<template>
  <div>
    <b-sidebar
      id="sidebar-edit-contract"
      sidebar-class="sidebar-lg"
      :visible="importEadSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateImportEadSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Nova importação</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <file-chooser
            class="mb-1"
            id="ead-file"
            label="Enviar arquivo ENF"
            v-model="eadFile"
            :thereIsError="v$.$error.eadFile"
            errorMessage="O arquivo deve possuir um tamanho de até 10MB"
            only-sheets
          />

          <file-chooser
            id="dna-file"
            label="Enviar arquivo DNA"
            v-model="dnaFile"
            :thereIsError="v$.$error.dnaFile"
            errorMessage="O arquivo deve possuir um tamanho de até 10MB"
            only-sheets
          />
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import { required } from "@vuelidate/validators";
import FileChooser from "@/modules/shared/components/FileChooser.vue";
import maxFileSize from "../../../validators/file";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    FileChooser,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      eadFile: null,
      dnaFile: null,
    };
  },
  computed: {
    ...mapGetters({
      importEadSidebar: types.IMPORT_EAD_SIDEBAR,
    }),
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      eadFile: {
        required,
        valid(file) {
          return !file || maxFileSize(file.size);
        },
      },
      dnaFile: {
        required,
        valid(file) {
          return !file || maxFileSize(file.size);
        },
      },
    };
  },
  methods: {
    ...mapMutations({
      mutateImportEadSidebar: types.MUTATE_IMPORT_EAD_SIDEBAR,
    }),
    ...mapActions({
      saveImportEad: types.SAVE_IMPORT_EAD,
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const request = new FormData();
      request.append("file", this.eadFile);
      request.append("dnaFile", this.dnaFile);
      this.saveImportEad(request)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Arquivo importado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateImportEadSidebar({ visible: false });
          this.importEadSidebar.saveAction();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: error.response.data.error,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao importar o arquivo. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clear() {
      this.eadFile = undefined;
      this.dnaFile = undefined;
      this.v$.$reset();
    },
  },
};
</script>
